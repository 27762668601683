
$primaryColor: #2876D0;
$primaryColorOverlay: rgba(40, 118, 208, 0.12);
$secondaryColor: #0F3551;
$accentColor: #970000;
$grayDarkColor: #0F3551;

$logoGradient: linear-gradient(45deg, $primaryColor 30%, $primaryColor);

$colorBeige: #e6e2e2;
$colorGrey: rgba(232, 232, 232, 0.5);

$logoBackground: url("/images/logo/cloud-transparent-mono.svg") no-repeat center;
$logoBackgroundTablet: url("/images/logo/cloud-transparent-mono-48-48.svg") center / contain no-repeat;
$logoBackgroundLanding: url("/images/logo/cloud-transparent-mono-long.svg") center / contain no-repeat;
$logoBackgroundMobile: url("/images/logo/n21-logo-mobile.svg") center / contain no-repeat;
$navbarLogo: url("/images/logo/navbar-cloud-logo.svg") center / contain no-repeat;

aside.nav-sidebar{
    .sc-branding{
        padding: 0;
        .cloud-logo{
            width: 100%;
            margin: 0;
            padding: 30px;
        }
        .sc-title {
            display: none;
        }
    }

    @media (max-width: 991px) {
        .sc-branding{
            padding: 0;
            .cloud-logo{
                background: $logoBackgroundTablet;
            }
        }
    }
}
body {
    header.top-margin {
        margin-top: 0;
    }
    header {


        .navbar-light {
            #title {
                padding: 0 !important;
            }

            .cloud-logo {
                background: $logoBackgroundLanding !important;
                margin-right: 0 !important;
                margin-top: -0.3rem !important;
                height: 3.5rem !important;
                width: 12rem !important;
                margin-left: -10px;
            }
            .minimal-button .dropdown-toggle {
                margin-top: 3.2px;
            }
            @media (max-width: 991px) {
                .cloud-logo {
                    background: $logoBackgroundMobile !important;
                 }
            }
        }
    }
}

.primary-color{
    color: $primaryColor;
    display: none;
}
.secondary-color {
    color: $secondaryColor;
    display: none;
}
@import "./colors";
@import "./sc-theme/sc-theme";

@import "./tether/tether.min.css";
@import "./bootstrap/scss/bootstrap-flex";

$fa-font-path: "/vendor-optimized/font-awesome/fonts";
$mdi-font-path: "/vendor-optimized/@mdi/font/fonts";
@import "@mdi/font/scss/materialdesignicons.scss";
@import "font-awesome/scss/font-awesome.scss";
@import "./chosen/bootstrap-chosen";
@import "./datetimepicker/jquery.datetimepicker.min.css";
@import "./toggle/bootstrap-toogle.min.css";

@import "./tabbar/tabbar";

/* customize bootstrap stuff in /bootstrap/scss/_custom.scss */

* {
	-webkit-font-smoothing: antialiased;
	text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	hyphens: auto;
}

.section-team,
.section-partner,
.section-about,
.section-community {
	h4 {
		font-weight: 500;
		margin: 30px 0px 20px 0px !important;
		width: 100%;
		display: block;
	}

	h5 {
		font-style: italic;
		margin: 30px 0px 20px 0px;
		width: 100%;
		display: block;
	}
}

.section-title h3 {
	color: $primaryColor;
	font-weight: 700;
	margin-top: 10px;
}

body h4:not(:first-of-type) {
	margin-top: 1rem;
}

a {
	text-decoration: none;

	&:hover {
		text-decoration: none;
	}
}

a[data-toggle="collapse"],
a[data-toggle="collapse"]:hover,
a[data-toggle="collapse"]:active,
a[data-toggle="collapse"]:focus {
	color: inherit;
	text-decoration: none !important;
}

img {
	max-width: 100%;
}

.btn-link {
	margin: 0;
	padding: 0;
}

p.small {
	line-height: 18px;
	font-size: 12px;
}

.no-padding {
	padding: 0px !important;
}

.pull-right {
	float: right;
}

.text-center {
	text-align: center;
}

label {
	font-weight: bold;
}

video {
	width: 100%;
	height: auto;
	object-fit: cover;
}

audio {
	width: 100%;
}

.pagination-wrapper {
	text-align: center;
	width: 100%;
}

label,
.noselect {
	user-select: none;
}

.hidden {
	display: none;
}

.show {
	display: initial;
}

[data-toggle="collapse"] {
	&[aria-expanded="true"] {
		[data-expand] {
			display: none;
		}
	}

	&[aria-expanded="false"] {
		[data-collapse] {
			display: none;
		}
	}
}

.btn-loading {
	font-size: 0px !important;
	transition: width 0.3s;

	&:before {
		content: "\f1ce";
		animation: fa-spin 2s infinite linear;
		font-family: "FontAwesome";
		display: inline-block;
		width: 20px;
		height: 20px;
		font-size: 16px;
	}
}

.table-wrapper {
	max-width: 100%;
	display: block;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	margin-bottom: 1rem;

	table {
		margin-bottom: 0;
	}
}

footer {
	display: block;

	nav {
		ul {
			list-style: none;
			display: flex;
			justify-content: center;
			flex-wrap: wrap;

			li {
				display: inline-block;
				padding: 0 4px;

				a, span {
					align-items: center;
					padding: 0 0.1rem;
					width: max-content;
				}
			}
		}
	}

	.mobile-app {

		img {
			height: 70px;
		}

		.ios-app {
			img {
				padding: 11.5px;
			}
		}
	}

	&.small-footer {
		@include media-breakpoint-down(xs) {
			background: $colorBeige;
			margin-top: 50px;

			img {
				clear: both;
				display: block;
				margin: 0 auto 10px;
			}
		}

		.imprint {
			.center {
				text-align: center;
				align-items: center;

				.made-with-love {
					margin-top: 8px;

					.heart {
						color: $primaryColor;
					}
				}
			}

			#footer-logo {
				width: auto;
				max-width: 175px;
				height: auto;
				max-height: 44px;
			}
		}
	}

	.made-with-love {
		.poweredby-logo {
			height: 1em;
			vertical-align: middle;
		}
	}
}

@keyframes fa-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

.notification.hidden {
	display: none;
}

form.inline {
	display: inline;

	button:not(.no-inline-styling) {
		-webkit-appearance: none;
		appearance: none;
		background: transparent;
		border: 0px;
	}
}

.margin-top {
	margin-top: 30px;
}

.padding-top {
	padding-top: 30px;
}

.btn-tertiary,
.btn.btn-tertiary,
.list-group-item.btn-tertiary {
	background-color: $colorGrey;
}

.btn-thin {
	padding-left: 15px !important;
	padding-right: 15px !important;
}

.btn.hidden {
	display: none;
}

.alert-fixed {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 9999;
	border-radius: 0;
}

.dropdown-item {
	cursor: pointer;
}

.collapse-icon {
	display: inline-block;
	vertical-align: baseline;
	text-align: center;
	width: 1em;
}

.window-inline .hide-inline {
	display: none;
}

.card-deck-row {
	& > div {
		margin-bottom: 1.5rem;
	}

	.card {
		max-width: 100% !important;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;

		.card-block {
			max-width: 100%;
			width: 100%;
			flex: 1 1 0;
		}

		.card-footer {
			align-self: flex-end;
			flex: 0 1 0;
			width: 100%;
		}
	}
}

.print-only {
	display: none;
}

@media print {
	.print-only {
		display: initial;
	}
}

.ckcontent {
	margin: 10px 0;
	color: #373a3c;
	text-decoration: none;

	&:hover {
		text-decoration: none;
	}

	p {
		font-size: 1rem;
		margin-bottom: 0.25rem;
	}

	img,
	video {
		max-width: 100%;
	}

	table {
		display: table;
		max-width: 100%;
		overflow: auto;
	}

	blockquote {
		color: grey;
		margin-left: 1rem;
		border-left: 2px solid grey;
		padding-left: 5px;
	}
}

.container .jumbotron {
	padding: 2rem 2rem;
	margin: 2rem 0rem;
}

.form-control {
	hyphens: none;
}

.position-absolute {
	position: absolute !important;
}

.externalLink:after {
	font-family: "FontAwesome";
	content: "  \f08e";
	font-size: 0.7em;
}

.skipLinks a {
	position: absolute;
	left: -50rem;
	width: 1px;
	height: 1px;
	top: auto;

	&:focus {
		left: auto;
		display: block;
		height: auto;
		width: 100%;
		text-align: center;
		top: 5rem;
	}
}

@media (max-width: 768px) {
	.skipLinks {
		a:focus {
			top: 8rem;
			z-index: 1;
		}
	}
}

select {
	width: 100%;
}

.title {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.no-bottom-margin {
	margin-bottom: 0 !important;
}
